<template>
  <div class="main-content">
    <page-title :name="$store.state.rule_doc_header.name" route_name="documents"/>
    <div class="section">
      <div class="row">
        <div class="full">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div v-html="$store.state.rule_doc_content ? $store.state.rule_doc_content.content : ''"></div>
                </article>
                <div class="col" style="margin-top: 20px" v-for="(documents, index) in $store.state.rule_doc_docs">
                  <div :key="index" class="col-one-quarter">
                    <article class="post post-tp-8">
                      <h3 class="title-5">
                        <a :href="documents.url">{{ documents.name }}</a>
                      </h3>
                      <div class="meta-tp-2">
                        <div class="date"><span>{{ documents.created_at | human_date }}</span></div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '../components/layouts/PageTitle.vue';

export default {
  components: { PageTitle },
  async asyncData (context) {
    await context.store.dispatch('ruleDocuments', context.route.params.id);
  },
  name: 'RuleDocuments',
  metaInfo () {
    return this.$seo(this.$store.state.rule_doc_header.name);
  },
  watch: {
    '$route.params.id' (id) {
      console.log(id);
    }
  }
};
</script>
<style scoped>
</style>
